.footer {
  position: relative;
  padding-top: 2rem;
  font-size: var(--font-size-sm);
  color: var(--secondary-color);
  z-index: 10;
}

.footer-content {
  border-top: 1px solid var(--divider-color);
  margin: 1rem 0 0;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem 1.5rem;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.footer-logo svg {
  width: auto;
  height: 1rem;
}

.footer-links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-left: 0;
  letter-spacing: -0.2px;
}

.footer-links a {
  transition: color var(--transition-duration) var(--transition-fn);
  padding: 0.5rem;
  padding-left: 0;
  white-space: nowrap;
}

.footer-links a:hover {
  color: var(--primary-color);
}

.footer-right {
  display: flex;
  align-items: center;
}

.social-links {
  display: flex;
  gap: 0.5rem;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  transition: color var(--transition-duration) var(--transition-fn);
}

.social-links a:hover {
  color: var(--primary-color);
}

.social-links svg {
  width: 16px;
  height: 16px;
}

.footer-bottom {
  padding: 0 0 0.3rem;
  display: flex;
  gap: 1rem;
  color: var(--tertiary-color);
  font-size: var(--font-size-xs);
  letter-spacing: -0.2px;
  margin-left: 0;
}

.footer-bottom a {
  padding: 0.5rem;
  padding-left: 0;
  transition: color var(--transition-duration) var(--transition-fn);
}

.footer-bottom a:hover {
  color: var(--secondary-color);
}

@media (max-width: 650px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .footer-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .footer-links {
    margin-left: 0;
  }
  
  .footer-bottom {
    margin-left: 0;
  }
  
  .footer-bottom a {
    padding: 0.3125rem;
    padding-left: 0;
  }
} 