.home-page {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.hero-section {
  position: relative;
  min-height: 80vh;
  display: flex;
  align-items: center;
  padding: 5rem 1rem 3rem;
}

.background-graphic {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
  background-attachment: scroll;
}

/* Add these properties to ensure full rendering */
html, body {
  background-color: var(--primary-bg-color);
}

/* Ensure background extends beyond viewport */
.background-svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  filter: blur(30px);
  transform: scale(1.2); /* Slightly scale up to avoid edge issues */
}

/* Improve the fade gradient to eliminate potential gaps */
.background-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, 
                             rgba(255, 255, 255, 0) 0%, 
                             rgba(255, 255, 255, 0.7) 85%, 
                             var(--primary-bg-color) 100%);
  transform: translateY(0); /* Force GPU acceleration */
  will-change: transform; /* Hint for browser to prerender */
}

/* Default: show light mode paths, hide dark mode paths */
.light-mode-paths {
  display: block;
}

.dark-mode-paths {
  display: none;
}

/* Dark mode background support */
@media (prefers-color-scheme: dark) {
  .background-fade {
    background: linear-gradient(180deg, 
                               rgba(0, 0, 0, 0) 0%, 
                               rgba(0, 0, 0, 0.7) 85%, 
                               #121212 100%); /* Dark background color */
  }
  
  /* Apply a darker filter to the SVG in dark mode */
  .background-svg {
    filter: blur(30px) brightness(0.6);
  }
  
  /* Switch path visibility in dark mode */
  .light-mode-paths {
    display: none;
  }
  
  .dark-mode-paths {
    display: block;
  }
  
  /* Text color adjustments for dark mode */
  .description {
    color: rgba(255, 255, 255, 0.87);
  }
  
  .mission-card {
    color: #000; /* Ensure card text remains dark */
  }
  
  /* Mission card dark mode styling */
  .mission-card-content {
    background-color: #262626;
    border-color: rgba(85, 85, 85, 0.8);
    box-shadow: 
      0 10px 0 0 rgba(0,0,0,0.3),
      0 16px 20px rgba(0,0,0,0.4),
      0 0 30px rgba(119, 34, 221, 0.2);
  }
  
  .mission-card-content:hover {
    box-shadow: 
      0 15px 0 0 rgba(0,0,0,0.25),
      0 20px 25px rgba(0,0,0,0.35),
      0 0 40px rgba(119, 34, 221, 0.3);
  }
  
  .mission-card .pixelated-gradient-text {
    /* Keep the gradient effect for the title */
  }
  
  .mission-card p {
    color: rgba(255, 255, 255, 0.87);
  }
  
  .mission-difficulty {
    background-color: #333;
    color: rgba(255, 255, 255, 0.87);
  }
  
  .mission-likes {
    color: rgba(255, 255, 255, 0.87);
  }
  
  h1 {
    color: #fff;
  }
  
  /* Additional iOS-specific adjustments */
  @supports (-webkit-touch-callout: none) {
    .background-graphic {
      opacity: 0.85; /* Slightly reduce opacity on iOS */
    }
  }
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
}

.text-content {
  flex-shrink: 1;
  margin-top: 1rem;
  max-width: 50%;
  text-align: left;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 3.75rem;
  line-height: 1.03;
  font-weight: var(--font-weight-medium);
  letter-spacing: -1px;
  margin-bottom: 1.5rem;
}

.gradient-text {
  display: inline-block;
  background: radial-gradient(
    circle at top left,
    #099ef1 0%,
    #6863f8 18.82%,
    #d84ffa 32.6%,
    #f058c5 52.83%,
    #ff4f90 68.03%,
    #ff6558 87.66%,
    #ff891f 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.pixelated-text {
  font-family: 'Press Start 2P', cursive;
  letter-spacing: -0.1rem;
  line-height: 1.5;
  font-size: 2.55rem;
}

.pixelated-gradient-text {
  font-family: 'Press Start 2P', cursive;
  letter-spacing: -0.1rem;
  line-height: 1.5;
  display: inline-block;
  background: radial-gradient(
    circle at top left,
    #099ef1 0%,
    #6863f8 18.82%,
    #d84ffa 32.6%,
    #f058c5 52.83%,
    #ff4f90 68.03%,
    #ff6558 87.66%,
    #ff891f 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 2.55rem;
}

.description {
  font-size: var(--font-size-lg);
  font-weight: 600;
  line-height: 2;
  color: #000;
  max-width: 380px;
  letter-spacing: -0.02em;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;
  width: 100%;
  max-width: 320px;
  perspective: 800px;
  justify-content: center;
  align-items: flex-start;
}

.cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff1493;
  color: #fff;
  font-size: var(--font-size-md);
  font-weight: 700;
  letter-spacing: -0.02em;
  padding: 0.5rem 0.5rem;
  border-radius: 30px;
  transition: all var(--transition-duration) var(--transition-fn);
  width: 280px;
  height: 90px;
  text-align: center;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border: 2px solid rgba(255,255,255,0.2);
  overflow: hidden;
  order: 2;
}

.cta-button:before {
  display: none;
}

.cta-button:after {
  display: none;
}

.cta-button:hover {
  background-color: #dc1083;
  transform: translateY(2px);
}

.cta-button:active {
  transform: translateY(4px);
}

.secondary-button {
  background-color: #7722DD;
  height: 90px;
  order: 1;
}

.secondary-button:hover {
  background-color: #6611CC;
}

.secondary-button:active {
}

.mission-card {
  width: 380px;
  height: 450px;
  flex-shrink: 0;
  position: relative;
  margin-right: -20px;
  perspective: 800px;
}

.mission-card-content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem;
  -webkit-transform: perspective(800px) rotateX(20deg);
  transform: perspective(800px) rotateX(20deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 
    0 15px 0 0 rgba(0,0,0,0.15),
    0 20px 25px rgba(0,0,0,0.2),
    0 0 40px rgba(119, 34, 221, 0.4);
  border: 2px solid rgba(255,255,255,0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all var(--transition-duration) var(--transition-fn);
}

.mission-card-content:hover {
  -webkit-transform: perspective(800px) rotateX(0deg) translateY(-5px);
  transform: perspective(800px) rotateX(0deg) translateY(-5px);
  box-shadow: 
    0 10px 0 0 rgba(0,0,0,0.2),
    0 16px 20px rgba(0,0,0,0.3),
    0 0 30px rgba(119, 34, 221, 0.3);
}

.mission-card .pixelated-gradient-text {
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.mission-card p {
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--secondary-color);
  margin-bottom: 1rem;
  font-weight: 500;
  letter-spacing: -1.7px;
}

.mission-card-image {
  width: 100%;
  height: 140px;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  object-fit: cover;
  border: 1px solid #7722DD;
}

.mission-card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.mission-difficulty {
  background-color: #f0f0f0;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.mission-action-button {
  background-color: #7722DD;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.mission-action-button:hover {
  background-color: #6611CC;
  transform: translateY(-2px);
}

.mission-action-button:active {
  transform: translateY(0);
}

.mission-likes {
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--secondary-color);
}

@media (max-width: 1000px) {
  h1 {
    font-size: 3.5rem;
  }
  
  .pixelated-text {
    font-size: 2.21rem;
  }
  
  .pixelated-gradient-text {
    font-size: 2.21rem;
  }
  
  .mission-card {
    width: 340px;
    height: 400px;
    margin-right: -10px;
  }
  
  .mission-card-image {
    height: 120px;
  }
  
  .button-container {
    max-width: 300px;
  }
  
  .cta-button {
    width: 260px;
    height: 90px;
  }
  
  .secondary-button {
    height: 90px;
  }
}

@media (max-width: 820px) {
  h1 {
    font-size: 3rem;
  }
  
  .pixelated-text {
    font-size: 1.87rem;
  }
  
  .pixelated-gradient-text {
    font-size: 1.87rem;
  }
  
  .description {
    font-size: var(--font-size-lg);
    max-width: 300px;
  }
}

.mission-card-image {
  height: 190px;
}

.mission-card {
  width: 380px;
  height: 500px;
  margin-right: -10px;
}


@media (max-width: 650px) {
  .content-container {
    flex-direction: column;
    gap: 3rem;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    max-width: 100%;
  }
  
  h1 {
    font-size: 3.25rem;
  }
  
  .pixelated-text {
    font-size: 1.7rem;
  }
  
  .pixelated-gradient-text {
    font-size: 1.7rem;
  }
  
  .description {
    margin-top: 0.5rem;
    max-width: 420px;
  }
  
  .mission-card {
    width: 100%;
    max-width: 320px;
    height: 360px;
    margin: 0;
  }
  
  .mission-card .pixelated-gradient-text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .mission-card p {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
  }
  
  .mission-card-content {
    padding: 1.5rem;
  }
  
  .mission-card-image {
    height: 100px;
    margin-bottom: 1rem;
  }
  
  .button-container {
    margin-top: 2.5rem;
    max-width: 280px;
    align-items: center;
  }
  
  .cta-button {
    width: 250px;
    height: 90px;
    font-size: var(--font-size-md);
  }
  
  .secondary-button {
    height: 90px;
  }
}

@media (max-width: 450px) {
  h1 {
    font-size: 2.5rem;
    line-height: 1.1;
  }
  
  .pixelated-text {
    font-size: 1.36rem;
  }
  
  .pixelated-gradient-text {
    font-size: 1.36rem;
  }
  
  .description {
    font-size: var(--font-size-md);
  }
  
  .mission-card {
    height: 300px;
    max-width: 280px;
  }
  
  .mission-card .pixelated-gradient-text {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
  
  .mission-card p {
    font-size: 1rem;
    margin-bottom: 0.6rem;
  }
  
  .mission-card-content {
    padding: 1.2rem;
  }
  
  .mission-card-image {
    height: 80px;
    margin-bottom: 0.8rem;
  }
  
  .mission-action-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
  
  .mission-likes {
    font-size: 0.8rem;
  }
  
  .button-container {
    margin-top: 2rem;
    max-width: 260px;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .cta-button {
    width: 240px;
    height: 90px;
    font-size: var(--font-size-md);
    padding: 0.5rem 0.5rem;
    border-radius: 30px;
  }
  
  .secondary-button {
    height: 90px;
  }
  
  .cta-button:hover {
    transform: translateY(2px);
  }
  
  .cta-button:active {
    transform: translateY(3px);
  }
}

@media (max-width: 380px) {
  .button-container {
    flex-direction: column;
    max-width: 220px;
    gap: 1.5rem;
  }
  
  .cta-button {
    width: 220px;
    height: 90px;
  }
  
  .secondary-button {
    height: 90px;
  }
  
  .mission-card {
    height: 340px;
    width: 300px;
  }

  .mission-card-image {
    height: 120px;
  }
  
}

/* iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .cta-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    /* Make sure touch events are captured properly */
    cursor: pointer;
  }
  
  .cta-button:hover {
    transform: translateY(2px);
  }
  
  .cta-button:active,
  .cta-button.active {
    transform: translateY(4px);
  }

  .secondary-button:active,
  .secondary-button.active {
    transform: translateY(4px);
  }
}

/* Add a script to handle touch events for iOS */
@media screen {
  @supports (-webkit-touch-callout: none) {
    /* This will be applied after the JS is added */
    .js-touch-device .cta-button:hover {
      transform: none;
    }
  }
}

/* iOS-specific dark mode handling */
.ios-device.ios-dark-mode .background-fade {
  background: linear-gradient(180deg, 
                             rgba(0, 0, 0, 0) 0%, 
                             rgba(0, 0, 0, 0.7) 85%, 
                             #121212 100%);
}

.ios-device.ios-dark-mode .background-svg {
  filter: blur(30px) brightness(0.6);
}

.ios-device.ios-dark-mode .light-mode-paths {
  display: none;
}

.ios-device.ios-dark-mode .dark-mode-paths {
  display: block;
}

.ios-device.ios-dark-mode .background-graphic {
  opacity: 0.85;
}

/* iOS-specific text color adjustments */
.ios-device.ios-dark-mode .description {
  color: rgba(255, 255, 255, 0.87);
}

.ios-device.ios-dark-mode h1 {
  color: #fff;
}

/* iOS-specific dark mode */
.ios-device.ios-dark-mode .survey-container {
  background: #262626;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
}

.ios-device.ios-dark-mode .survey-header,
.ios-device.ios-dark-mode .survey-header h1,
.ios-device.ios-dark-mode .survey-question {
  color: #fff;
}

.ios-device.ios-dark-mode .survey-option {
  color: #fff;
  border-color: #555;
  background-color: #333;
}

.ios-device.ios-dark-mode .survey-input {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.ios-device.ios-dark-mode .survey-next {
  background-color: transparent;
  color: #bf94ff;
}

.ios-device.ios-dark-mode .survey-back {
  color: #bf94ff;
}

/* iOS-specific dark mode for mission card */
.ios-device.ios-dark-mode .mission-card-content {
  background-color: #262626;
  border-color: rgba(85, 85, 85, 0.8);
  box-shadow: 
    0 10px 0 0 rgba(0,0,0,0.3),
    0 16px 20px rgba(0,0,0,0.4),
    0 0 30px rgba(119, 34, 221, 0.2);
}

.ios-device.ios-dark-mode .mission-card-content:hover {
  box-shadow: 
    0 15px 0 0 rgba(0,0,0,0.25),
    0 20px 25px rgba(0,0,0,0.35),
    0 0 40px rgba(119, 34, 221, 0.3);
}

.ios-device.ios-dark-mode .mission-card p {
  color: rgba(255, 255, 255, 0.87);
}

.ios-device.ios-dark-mode .mission-likes {
  color: rgba(255, 255, 255, 0.87);
}

/* Force hardware acceleration for smoother animations */
.navbar, .navbar.hidden {
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

/* Optimized background rendering */
.background-rendered {
  transition: opacity 0.2s ease-out;
}

/* iOS-specific optimizations */
@supports (-webkit-touch-callout: none) {
  .background-graphic {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  
  /* Ensure background color matches on iOS */
  body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-bg-color);
    z-index: -3;
  }
}

/* Dark mode button styles */
@media (prefers-color-scheme: dark) {
  .cta-button {
    background-color: #5511AA; /* Darker purple */
    border: 2px solid rgba(255,255,255,0.15);
  }
  
  .cta-button:hover {
    background-color: #440099;
  }
  
  .secondary-button {
    background-color: #CC0088; /* Darker pink */
  }
  
  .secondary-button:hover {
    background-color: #BB0077;
  }
}

/* iOS dark mode button styles */
.ios-device.ios-dark-mode .cta-button {
  background-color: #5511AA;
  border: 2px solid rgba(255,255,255,0.15);
}

.ios-device.ios-dark-mode .cta-button:hover {
  background-color: #440099;
}

.ios-device.ios-dark-mode .secondary-button {
  background-color: #CC0088;
}

.ios-device.ios-dark-mode .secondary-button:hover {
  background-color: #BB0077;
} 