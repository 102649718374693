:root {
  /* Colors */
  --primary-color: #111;
  --secondary-color: #555;
  --secondary-color-alpha: rgba(82, 82, 91, 0.8);
  --tertiary-color: #888;
  --primary-bg-color: #fff;
  --secondary-bg-color: #f5f5f5;
  --divider-color: rgba(0, 0, 0, 0.1);
  
  /* Spacing */
  --max-width: 1200px;
  --page-padding: 1rem;
  
  /* Typography */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 2rem;
  --font-size-4xl: 2.5rem;
  
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  
  /* Borders */
  --border-radius: 4px;
  --border-radius-lg: 8px;
  
  /* Transitions */
  --transition-duration: 200ms;
  --transition-fn: cubic-bezier(0.4, 0, 0.2, 1);
}

body {
  color: var(--primary-color);
  background-color: var(--primary-bg-color);
  font-size: var(--font-size-md);
  line-height: 1.5;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Force a background color before any content loads */
html::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-bg-color);
  z-index: -2;
}

.container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--page-padding);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #fff;
    --secondary-color: #bbb;
    --secondary-color-alpha: rgba(187, 187, 187, 0.8);
    --tertiary-color: #888;
    --primary-bg-color: #111;
    --secondary-bg-color: #222;
    --divider-color: rgba(255, 255, 255, 0.1);
  }
}

/* Utility classes */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
} 