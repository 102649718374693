html, body {
  height: 100%;
  min-height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  overflow: visible;
}

body {
  margin-top: 1px;
  background-color: #ffffff;
}

.App {
  text-align: center;
  height: 100%;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  z-index: 2;
}

.location {
  display: none;
}

.content-wrapper {
  position: relative;
  top: 40%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  height: auto;
  transform: translateY(-50%);
  justify-content: center;
  padding-top: max(0.3rem, 0.2vh);
  min-height: 100%;
  z-index: 5;
}

.logo-button {
  font-size: clamp(3rem, 12vw, 7rem);
  font-weight: 900;
  color: #000;
  text-shadow: 0 0 clamp(10px, 2vw, 20px) rgba(128, 0, 255, 0.7),
               0 0 2.5px rgba(0, 0, 0, 0.3);
  filter: drop-shadow(0 0 1.5px rgba(0, 0, 0, 0.4));
  letter-spacing: clamp(2px, 1vw, 2px);
  background: none;
  border: none;
  padding: clamp(0.8rem, 2vh, 1.5rem);
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  width: fit-content;
  min-width: clamp(250px, 85vw, 450px);
  max-width: clamp(300px, 90vw, 600px);
  margin-bottom: max(2vh, 0.8rem);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* Add the radiating glow effect with multiple layers */
.logo-button::before {
  content: "";
  display: none;
}

.logo-button::after {
  display: none;
}

/* Logo container and glow layers */
.logo-button-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: clamp(250px, 85vw, 450px);
  max-width: clamp(300px, 90vw, 600px);
  height: auto;
  margin-bottom: max(2vh, 0.8rem);
  padding-top: max(3.9vh, 2rem);
  z-index: 1;
  transform-style: preserve-3d;
  perspective: 1000px;
}

/* Base styles for all glow layers */
.glow-layer-1, 
.glow-layer-2, 
.glow-layer-3,
.glow-layer-4,
.glow-layer-5 {
  content: "LUV.WTF";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: clamp(2px, 1vw, 2px);
  pointer-events: none;
  z-index: -1;
  text-align: center;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* First glow layer */
.glow-layer-1 {
  font-size: clamp(3.2rem, 12.4vw, 7.2rem);
  color: rgba(128, 0, 255, 0.2);
  text-shadow: 0 0 clamp(25px, 5vw, 40px) rgba(128, 0, 255, 0.8),
               0 0 clamp(40px, 7vw, 75px) rgba(128, 0, 255, 0.6),
               0 0 clamp(55px, 9vw, 105px) rgba(128, 0, 255, 0.4);
  filter: blur(1px);
  opacity: 0.8;
  transform: translate(-50%, calc(-50% + 3px)) scale(1.03);
  transform-origin: center center;
}

/* Second glow layer */
.glow-layer-2 {
  font-size: clamp(3.4rem, 12.8vw, 7.4rem);
  color: rgba(128, 0, 255, 0.18);
  text-shadow: 0 0 clamp(30px, 6vw, 45px) rgba(128, 0, 255, 0.7),
               0 0 clamp(45px, 8vw, 80px) rgba(128, 0, 255, 0.5),
               0 0 clamp(60px, 10vw, 115px) rgba(128, 0, 255, 0.3);
  filter: blur(2px);
  opacity: 0.7;
  transform: translate(-50%, calc(-50% + 6px)) scale(1.06);
  transform-origin: center center;
}

/* Third glow layer */
.glow-layer-3 {
  font-size: clamp(3.6rem, 13.2vw, 7.6rem);
  color: rgba(128, 0, 255, 0.15);
  text-shadow: 0 0 clamp(35px, 7vw, 50px) rgba(128, 0, 255, 0.6),
               0 0 clamp(50px, 9vw, 85px) rgba(128, 0, 255, 0.4),
               0 0 clamp(65px, 11vw, 120px) rgba(128, 0, 255, 0.2);
  filter: blur(3px);
  opacity: 0.6;
  transform: translate(-50%, calc(-50% + 9px)) scale(1.09);
  transform-origin: center center;
}

/* Fourth glow layer */
.glow-layer-4 {
  font-size: clamp(3.8rem, 13.6vw, 7.8rem);
  color: rgba(128, 0, 255, 0.12);
  text-shadow: 0 0 clamp(40px, 8vw, 55px) rgba(128, 0, 255, 0.5),
               0 0 clamp(55px, 10vw, 90px) rgba(128, 0, 255, 0.3),
               0 0 clamp(70px, 12vw, 125px) rgba(128, 0, 255, 0.15);
  filter: blur(4px);
  opacity: 0.5;
  transform: translate(-50%, calc(-50% + 12px)) scale(1.12);
  transform-origin: center center;
}

/* Fifth glow layer */
.glow-layer-5 {
  font-size: clamp(4rem, 14vw, 8rem);
  color: rgba(128, 0, 255, 0.1);
  text-shadow: 0 0 clamp(45px, 9vw, 60px) rgba(128, 0, 255, 0.4),
               0 0 clamp(60px, 11vw, 95px) rgba(128, 0, 255, 0.2),
               0 0 clamp(75px, 13vw, 130px) rgba(128, 0, 255, 0.1);
  filter: blur(5px);
  opacity: 0.4;
  transform: translate(-50%, calc(-50% + 15px)) scale(1.15);
  transform-origin: center center;
}

.led-tagline {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 700;
  color: rgba(153, 51, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin: max(1.5vh, 1rem) 0 max(6vh, 4rem);
  position: relative;
  text-shadow: 0 0 4px rgba(153, 51, 255, 0.2);
  display: flex;
}

.led-text {
  position: relative;
  display: inline-block;
  animation: ledScroll 28s linear infinite;
  transform: translateX(120%);
}

.led-text:nth-child(2) {
  position: absolute;
  left: 0%;
  animation: ledScroll 28s linear infinite;
  animation-delay: 14s;
  transform: translateX(150%);
}

.led-tagline::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: rgba(153, 51, 255, 0.7);
  text-shadow: 0 0 3px #9933ff,
               0 0 6px rgba(153, 51, 255, 0.6);
  animation: ledPulse 2s ease-in-out infinite alternate;
  z-index: 1;
  pointer-events: none;
}

@keyframes ledPulse {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes ledScroll {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(-200%);
  }
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .led-tagline {
    font-size: clamp(0.8rem, 2vw, 1.2rem);
    letter-spacing: 1px;
    animation-duration: 15s;
  }
}

/* iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .led-tagline {
    font-size: clamp(0.9rem, 2.2vw, 1.3rem);
    letter-spacing: 1.5px;
    animation: none;
    margin-left: 0;
    margin-right: 0;
    max-width: 100vw;
    overflow: hidden;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    position: relative;
    display: flex;
  }
  
  .led-text {
    display: inline-block;
    position: relative;
    width: auto;
    min-width: max-content;
    white-space: nowrap;
    color: rgba(153, 51, 255, 0.7);
    text-shadow: 0 0 3px #9933ff, 0 0 6px rgba(153, 51, 255, 0.6);
    padding: 0 5px;
    animation: ledTextScrollIOS 24s linear infinite;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(120%);
  }
  
  .led-text:nth-child(2) {
    position: absolute;
    left: 0%;
    animation: ledTextScrollIOS 24s linear infinite;
    animation-delay: 12s;
    transform: translateX(150%);
  }
  
  .led-tagline::before {
    display: none;
  }
  
  @keyframes ledTextScrollIOS {
    0% {
      transform: translateX(120%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
}

/* Larger screen adjustments */
@media (min-width: 1200px) {
  .led-text:nth-child(2) {
    transform: translateX(200%);
  }
  
  @supports (-webkit-touch-callout: none) {
    .led-text:nth-child(2) {
      transform: translateX(200%);
    }
  }
}

/* New Tagline Implementation */
.tagline-flicker {
  width: 100%;
  margin: max(0.8vh, 0.5rem) 0 max(3vh, 2rem);
  position: relative;
  text-align: center;
  background: transparent;
  overflow: visible;
}

.tagline-flicker .tagline-text {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 700;
  color: rgba(153, 51, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
  text-shadow: none;
  animation: 
    simpleFlicker 1.5s infinite;
  opacity: 0.95;
  padding: 0;
  border: none;
  background: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Simple flickering animation with repeating pattern */
@keyframes simpleFlicker {
  0%, 49.9% {
    opacity: 0.3;
    text-shadow: none;
  }
  50%, 100% {
    opacity: 0.95;
    text-shadow: none;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .tagline-flicker .tagline-text {
    font-size: clamp(0.8rem, 2vw, 1.2rem);
    letter-spacing: 1px;
  }
}

/* iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .tagline-flicker .tagline-text {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-size: clamp(0.9rem, 2.2vw, 1.3rem);
    animation: simpleFlicker 1.5s infinite !important;
    will-change: opacity, text-shadow, transform;
    animation-play-state: running !important;
  }
  
  /* iOS join-button overrides moved to ensure they're not overridden */
  .join-button {
    animation: buttonGlowFlicker 1.5s infinite !important; /* Use !important to ensure override */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: box-shadow, transform;
    animation-play-state: running !important;
  }
}

.features {
  display: flex;
  flex-direction: column;
  gap: max(4vh, 1.5rem);
  width: fit-content;
  position: relative;
  margin: 0 auto;
  padding: max(3vh, 1rem) 0 max(1.5vh, 0.5rem) 0;
  margin-bottom: max(2vh, 1rem);
  z-index: 10;
}

.feature {
  font-size: clamp(1.3rem, 2vw, 1.7rem);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: clamp(1px, 0.5vw, 1px);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: clamp(0.7rem, 1.5vh, 1.2rem) clamp(4.5rem, 8vw, 7rem);
  position: relative;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 2px solid #000;
  border-radius: clamp(2rem, 4vw, 3rem);
  background: #f5d6ff;
  box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.4),
              0 0 clamp(30px, 4vw, 50px) rgba(128, 0, 255, 0.2),
              0 0 clamp(45px, 6vw, 75px) rgba(255, 102, 255, 0.1);
  width: clamp(120px, 18vw, 140px);
  margin: 0 auto;
  transition: all 0.2s ease;
  text-align: center;
  animation: button-pulse 2s ease-in-out infinite;
  transform-origin: center;
}

/* Add delay to the second button */
.feature:nth-child(2) {
  animation-delay: 1s;
}

.feature.clickable {
  cursor: pointer;
}

.feature.clickable:hover {
  transform: translateY(-2px) scale(1);
  background: #f5d6ff;
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.5),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.3),
              0 0 clamp(60px, 8vw, 90px) rgba(255, 102, 255, 0.2);
  animation: none;
}

.feature.clickable:active {
  transform: translateY(0);
}

@keyframes button-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.4),
                0 0 clamp(30px, 4vw, 50px) rgba(128, 0, 255, 0.2),
                0 0 clamp(45px, 6vw, 75px) rgba(255, 102, 255, 0.1);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.5),
                0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.3),
                0 0 clamp(60px, 8vw, 90px) rgba(255, 102, 255, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 clamp(15px, 2.5vw, 25px) rgba(128, 0, 255, 0.4),
                0 0 clamp(30px, 4vw, 50px) rgba(128, 0, 255, 0.2),
                0 0 clamp(45px, 6vw, 75px) rgba(255, 102, 255, 0.1);
  }
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .feature {
    animation: button-pulse-mobile 2s ease-in-out infinite;
  }

  @keyframes button-pulse-mobile {
    0% {
      transform: scale(1);
      box-shadow: 0 0 15px rgba(128, 0, 255, 0.4),
                  0 0 30px rgba(128, 0, 255, 0.3),
                  0 0 45px rgba(255, 102, 255, 0.2);
    }
    50% {
      transform: scale(1.03);
      box-shadow: 0 0 25px rgba(128, 0, 255, 0.5),
                  0 0 50px rgba(128, 0, 255, 0.4),
                  0 0 75px rgba(255, 102, 255, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 15px rgba(128, 0, 255, 0.4),
                  0 0 30px rgba(128, 0, 255, 0.3),
                  0 0 45px rgba(255, 102, 255, 0.2);
    }
  }

  .feature.clickable:hover {
    transform: translateY(-2px) scale(1.03);
    box-shadow: 0 0 30px rgba(128, 0, 255, 0.6),
                0 0 60px rgba(128, 0, 255, 0.4),
                0 0 90px rgba(255, 102, 255, 0.3);
  }
}

.heart {
  height: clamp(0.648rem, 1.44vw, 1.08rem);
  width: clamp(0.648rem, 1.44vw, 1.08rem);
  position: absolute;
  left: clamp(1rem, 2vw, 1.5rem);
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(0) invert(0);
  object-fit: contain;
  display: block;
}

.heart-right {
  height: clamp(0.648rem, 1.44vw, 1.08rem);
  width: clamp(0.648rem, 1.44vw, 1.08rem);
  position: absolute;
  right: clamp(1rem, 2vw, 1.5rem);
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(0) invert(0);
  object-fit: contain;
  display: block;
}

.join-button {
  background-color: #9933ff;
  color: white;
  border: none;
  padding: clamp(1.5rem, 4vh, 2.5rem);
  font-size: clamp(1.2rem, 4vw, 2rem);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.5),
              0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.3),
              0 0 clamp(60px, 8vw, 90px) rgba(255, 102, 255, 0.2);
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: clamp(1px, 0.5vw, 2px);
  font-family: 'Montserrat', sans-serif;
  width: clamp(200px, 45vw, 300px);
  height: clamp(200px, 45vw, 300px);
  margin-top: max(2vh, 0.8rem);
  margin-bottom: 0.25rem;
  position: relative;
  z-index: 10;
  animation: buttonGlowFlicker 1.5s infinite;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2;
  white-space: normal;
  word-wrap: break-word;
}

/* Button glow flicker animation that syncs with the tagline flicker */
@keyframes buttonGlowFlicker {
  0%, 47.9% {
    box-shadow: 0 0 clamp(10px, 1.5vw, 15px) rgba(128, 0, 255, 0.3),
                0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.2),
                0 0 clamp(30px, 4vw, 45px) rgba(255, 102, 255, 0.1);
  }
  48%, 100% {
    box-shadow: 0 0 clamp(20px, 3vw, 30px) rgba(128, 0, 255, 0.6),
                0 0 clamp(40px, 5vw, 60px) rgba(128, 0, 255, 0.4),
                0 0 clamp(60px, 8vw, 90px) rgba(255, 102, 255, 0.3);
  }
}

.join-button:hover {
  background-color: #8000ff;
}

.learn-more-link {
  color: #9933ff;
  text-decoration: underline;
  font-size: clamp(0.9rem, 2.5vw, 1.2rem);
  font-weight: 600;
  margin-top: clamp(1.5rem, 3vh, 2rem);
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: relative;
  z-index: 10;
}

.learn-more-link:hover {
  color: #8000ff;
  text-decoration: underline;
}

.verification {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #000;
  position: relative;
  margin: 0;
  padding: 0;
  margin-top: clamp(0.8rem, 1.5vh, 1.2rem);
  z-index: 10;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.heart-popup {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.98);
  width: clamp(350px, 90vw, 600px);
  height: clamp(350px, 90vw, 600px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: clamp(1.2rem, 3.5vw, 1.8rem);
  line-height: 1.5;
  opacity: 0;
  visibility: hidden;
  transition: none;
  z-index: 1000;
  background-image: url('./heart2.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0 15px rgba(153, 51, 255, 0.9))
         drop-shadow(0 0 30px rgba(153, 51, 255, 0.7))
         drop-shadow(0 0 45px rgba(153, 51, 255, 0.5));
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%) scale(0.98);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  will-change: transform, opacity;
}

.heart-popup.visible {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}

.heart-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 230, 255, 0.95);
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
  cursor: pointer;
  will-change: opacity;
}

.heart-popup-overlay.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.15s ease-out;
}

.heart-popup-content {
  max-width: 65%;
  width: clamp(220px, 65%, 400px);
  font-weight: 400;
  padding: clamp(1.2rem, 2.5vh, 2.5rem);
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1.1rem, 3vw, 1.6rem);
  line-height: 1.4;
}

@media (min-height: 800px) {
  .content-wrapper {
    gap: 0;
  }

  .features {
    gap: 4vh;
  }

  .join-button {
    margin-top: 4vh;
    margin-bottom: 0.25rem;
  }

  .verification {
    margin-top: clamp(1rem, 2vh, 1.5rem);
  }
}

/* Add iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .heart {
    height: clamp(0.7rem, 2.1vw, 1.4rem);
    width: clamp(0.7rem, 2.1vw, 1.4rem);
    position: absolute;
    left: clamp(0.8rem, 1.8vw, 1.2rem);
    top: 50%;
    transform: translateY(-50%);
  }
  
  .heart-right {
    height: clamp(0.7rem, 2.1vw, 1.4rem);
    width: clamp(0.7rem, 2.1vw, 1.4rem);
    position: absolute;
    right: clamp(0.8rem, 1.8vw, 1.2rem);
    top: 50%;
    transform: translateY(-50%);
  }
  
  .logo-button {
    font-size: clamp(4rem, 14vw, 7.5rem);
    padding: clamp(1.2rem, 3.5vh, 2.5rem);
  }
  
  .feature {
    width: clamp(120px, 25vw, 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .join-button {
    margin-top: max(5vh, 2rem);
    margin-bottom: 0.25rem;
  }
  
  .verification {
    margin-top: clamp(1rem, 2vh, 1.5rem);
  }

  .features {
    gap: max(5.5vh, 2.2rem);
    padding: max(3.5vh, 1.2rem) 0 max(2vh, 0.8rem) 0;
  }
}

/* Match Popup Styles */
.match-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
  width: clamp(320px, 90vw, 480px);
  height: auto;
  max-height: clamp(500px, 80vh, 700px);
  background-color: #fdf2ff;
  background-image: linear-gradient(
    135deg,
    rgba(253, 242, 255, 1) 0%,
    rgba(252, 238, 255, 1) 40%,
    rgba(251, 235, 255, 1) 60%,
    rgba(250, 230, 255, 1) 100%
  );
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(153, 51, 255, 0.6),
              0 0 40px rgba(153, 51, 255, 0.4),
              0 0 60px rgba(153, 51, 255, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 1000;
  overflow: hidden;
  -webkit-transform: translate(-50%, -50%) scale(0.95);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform, opacity;
}

.match-popup.visible {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}

.match-popup-content {
  padding: clamp(1.5rem, 4vh, 2rem);
  display: flex;
  flex-direction: column;
  gap: clamp(1.2rem, 3.5vh, 1.8rem);
  max-height: 100%;
  overflow-y: auto;
}

.match-profile {
  display: flex;
  align-items: center;
  gap: clamp(1rem, 3vw, 1.5rem);
}

.profile-image-container {
  width: clamp(70px, 20vw, 100px);
  height: clamp(70px, 20vw, 100px);
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  text-align: left;
}

.profile-info h2 {
  margin: 0 0 0.2rem;
  font-size: clamp(1.3rem, 4vw, 1.8rem);
}

.profile-info p {
  margin: 0.2rem 0;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
}

.profile-info p:first-of-type {
  color: #444;
  font-weight: 500;
  margin-top: 0;
}

.profile-info p:nth-of-type(2) {
  margin-top: 0.6rem;
}

.profile-info p:nth-child(2) {
  padding-top: clamp(0.5rem, 2vh, 0.8rem);
}

.profile-link {
  color: #9933ff;
  text-decoration: none;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  display: inline-block;
  margin-top: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.profile-link:hover {
  text-decoration: underline;
}

.match-interests {
  text-align: center;
  font-size: clamp(1.08rem, 1.98vw, 1.44rem);
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}

.match-interests p {
  margin: 0;
}

.match-interests .highlight {
  color: #9933ff;
  font-weight: 600;
  font-size: clamp(1.08rem, 1.98vw, 1.44rem);
}

.edit-hint {
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  color: #aaa;
  font-weight: 500;
  margin-top: 0.5rem;
}

.match-activities {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: clamp(0.5rem, 2vw, 1rem);
}

.activity {
  flex: 1;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.activity.clickable {
  cursor: pointer;
  transition: all 0.2s ease;
}

.activity.clickable:hover {
  transform: translateY(-3px);
  filter: brightness(1.05);
}

.activity.clickable:hover .activity-image {
  box-shadow: 0 0 20px rgba(153, 51, 255, 0.5),
              0 0 40px rgba(153, 51, 255, 0.3),
              0 0 60px rgba(153, 51, 255, 0.2);
}

.activity.clickable:active {
  transform: translateY(-1px);
}

.activity-image {
  width: 100%;
  height: clamp(80px, 20vw, 120px);
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  box-sizing: border-box;
  box-shadow: 0 0 15px rgba(153, 51, 255, 0.3),
              0 0 30px rgba(153, 51, 255, 0.2);
}

.activity p {
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  margin: 0;
}

.activity-separator {
  font-size: clamp(1rem, 2.5vw, 1.4rem);
  font-weight: 400;
  align-self: center;
  margin-top: -45px;
}

.match-popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: clamp(1rem, 3vw, 1.5rem);
  margin-top: clamp(0.2rem, 0.5vh, 0.5rem);
}

.decline-button, .plan-button {
  flex: 1;
  padding: clamp(0.8rem, 2vh, 1.2rem);
  border-radius: clamp(1.5rem, 3vw, 2rem);
  font-size: clamp(1rem, 3vw, 1.4rem);
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: clamp(1px, 0.3vw, 2px);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.decline-button {
  background-color: #000;
  color: white;
  transition: background-color 0.2s ease;
  box-shadow: 0 0 clamp(10px, 2vw, 20px) rgba(0, 0, 0, 0.5);
}

.decline-button:hover {
  background-color: #333;
  box-shadow: 0 0 clamp(15px, 3vw, 25px) rgba(0, 0, 0, 0.6);
}

.plan-button {
  background-color: #9933ff;
  color: white;
  box-shadow: 0 0 clamp(10px, 2vw, 20px) rgba(128, 0, 255, 0.5);
  transition: background-color 0.2s ease;
}

.plan-button:hover {
  background-color: #8000ff;
}

/* Profiles Popup Styles */
.profiles-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
  width: clamp(320px, 90vw, 480px);
  height: auto;
  max-height: clamp(600px, 85vh, 800px);
  background-color: #fdf2ff;
  background-image: linear-gradient(
    135deg,
    rgba(253, 242, 255, 1) 0%,
    rgba(252, 238, 255, 1) 40%,
    rgba(251, 235, 255, 1) 60%,
    rgba(250, 230, 255, 1) 100%
  );
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(153, 51, 255, 0.6),
              0 0 40px rgba(153, 51, 255, 0.4),
              0 0 60px rgba(153, 51, 255, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 1000;
  overflow: hidden;
  -webkit-transform: translate(-50%, -50%) scale(0.95);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform, opacity;
}

/* Add a subtle inner glow to the profiles popup */
.profiles-popup::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(255, 153, 255, 0.15) 0%,
    rgba(255, 153, 255, 0.05) 60%,
    rgba(255, 153, 255, 0) 100%
  );
  border-radius: 30px;
  pointer-events: none;
}

.profiles-popup.visible {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}

.profiles-popup-content {
  padding: clamp(2.2rem, 5vh, 3rem) clamp(1.5rem, 4vh, 2rem) clamp(1.5rem, 4vh, 2rem);
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 2.5vh, 1.5rem);
  max-height: 100%;
  overflow-y: auto;
}

.profiles-header {
  text-align: center;
  font-size: clamp(1.2rem, 2.2vw, 1.6rem);
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: clamp(0.2rem, 0.8vh, 0.4rem);
  color: #000;
}

.profiles-header p {
  margin: 0;
}

.profiles-header .highlight {
  color: #9933ff;
  font-weight: 600;
  font-size: clamp(1.2rem, 2.2vw, 1.6rem);
}

.profiles-header .edit-hint {
  margin-top: 8px;
}

.profile-card {
  display: flex;
  align-items: center;
  gap: clamp(1rem, 3vw, 1.5rem);
  padding: clamp(1rem, 3vh, 1.5rem) 0;
  border-bottom: 1px solid rgba(153, 51, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.profile-card.clickable {
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 15px;
  padding: clamp(1rem, 3vh, 1.5rem) clamp(0.8rem, 2vw, 1.2rem);
  margin: 0 -clamp(0.8rem, 2vw, 1.2rem);
  border-bottom: 2px solid rgba(153, 51, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.profile-card.clickable:hover {
  background-color: rgba(255, 255, 255, 0.6);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(153, 51, 255, 0.2);
  border-bottom-color: rgba(153, 51, 255, 0.6);
}

.profile-card.clickable:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(153, 51, 255, 0.1);
}

.profile-card:last-child {
  border-bottom: 2px solid rgba(153, 51, 255, 0.3);
}

/* Close Button Styles */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  color: #9933ff;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  padding: 0;
  transition: color 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.close-button:hover {
  color: #8000ff;
}

.close-button:active {
  color: #6600cc;
  transform: scale(0.95);
}

/* iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .profiles-popup {
    width: clamp(330px, 92vw, 500px);
    max-height: clamp(620px, 85vh, 820px);
    transform: translate(-50%, -50%) scale(0.98);
    -webkit-transform: translate(-50%, -50%) scale(0.98);
  }
  
  .profiles-popup-content {
    padding: clamp(2.5rem, 6vh, 3.5rem) clamp(1.8rem, 4.5vh, 2.5rem) clamp(1.8rem, 4.5vh, 2.5rem);
    gap: clamp(1.2rem, 3vh, 1.8rem);
  }
  
  .profiles-header {
    font-size: clamp(1.2rem, 4vw, 1.5rem);
  }
  
  .profile-card {
    padding: clamp(1.2rem, 3.5vh, 1.8rem) 0;
  }
  
  .close-button {
    top: 15px;
    right: 10px;
    width: 36px;
    height: 36px;
    font-size: 32px;
    line-height: 32px;
    color: #9933ff;
    background-color: transparent;
  }
}

/* Logo Laser Animation */
.laser {
  position: absolute;
  background: linear-gradient(90deg, 
    rgba(153, 51, 255, 0), 
    rgba(153, 51, 255, 0.3), 
    rgba(255, 255, 255, 0.95), 
    rgba(153, 51, 255, 0.3), 
    rgba(153, 51, 255, 0));
  height: 200vh;
  width: 4px;
  transform-origin: top center;
  box-shadow: 0 0 15px rgba(153, 51, 255, 0.9),
              0 0 30px rgba(153, 51, 255, 0.7),
              0 0 45px rgba(153, 51, 255, 0.5),
              0 0 60px rgba(153, 51, 255, 0.3);
  animation: logoLaserGlossy 1.2s ease-out forwards;
  border-radius: 4px;
  z-index: 1;
}

/* Apply different delays to each logo laser */
.laser:nth-child(1) {
  animation-delay: 0s;
}

.laser:nth-child(2) {
  animation-delay: 0.15s;
  opacity: 0;
}

.laser:nth-child(3) {
  animation-delay: 0.3s;
  opacity: 0;
  animation-name: logoLaserGlossyAlt;
}

@keyframes logoLaserGlossy {
  0% {
    opacity: 0;
    width: 2px;
    box-shadow: 0 0 5px rgba(153, 51, 255, 0.7),
                0 0 10px rgba(153, 51, 255, 0.5);
  }
  15% {
    opacity: 1;
    width: 3px;
    box-shadow: 0 0 10px rgba(153, 51, 255, 0.9),
                0 0 20px rgba(153, 51, 255, 0.7),
                0 0 30px rgba(153, 51, 255, 0.5);
  }
  30% {
    opacity: 1;
    width: 4px;
    box-shadow: 0 0 15px rgba(153, 51, 255, 1),
                0 0 30px rgba(153, 51, 255, 0.9),
                0 0 45px rgba(153, 51, 255, 0.7),
                0 0 60px rgba(153, 51, 255, 0.5);
  }
  70% {
    opacity: 1;
    width: 4px;
    box-shadow: 0 0 15px rgba(153, 51, 255, 1),
                0 0 30px rgba(153, 51, 255, 0.9),
                0 0 45px rgba(153, 51, 255, 0.7),
                0 0 60px rgba(153, 51, 255, 0.5);
  }
  100% {
    opacity: 0;
    width: 2px;
    box-shadow: 0 0 5px rgba(153, 51, 255, 0.7),
                0 0 10px rgba(153, 51, 255, 0.5);
  }
}

@keyframes logoLaserGlossyAlt {
  0% {
    opacity: 0;
    width: 1px;
    box-shadow: 0 0 5px rgba(204, 102, 255, 0.7),
                0 0 10px rgba(204, 102, 255, 0.5);
  }
  20% {
    opacity: 1;
    width: 3.5px;
    box-shadow: 0 0 12px rgba(204, 102, 255, 0.9),
                0 0 24px rgba(204, 102, 255, 0.7),
                0 0 36px rgba(204, 102, 255, 0.5);
  }
  40% {
    opacity: 1;
    width: 5px;
    box-shadow: 0 0 18px rgba(204, 102, 255, 1),
                0 0 36px rgba(204, 102, 255, 0.9),
                0 0 54px rgba(204, 102, 255, 0.7),
                0 0 72px rgba(204, 102, 255, 0.5);
  }
  65% {
    opacity: 1;
    width: 5px;
    box-shadow: 0 0 18px rgba(204, 102, 255, 1),
                0 0 36px rgba(204, 102, 255, 0.9),
                0 0 54px rgba(204, 102, 255, 0.7),
                0 0 72px rgba(204, 102, 255, 0.5);
  }
  100% {
    opacity: 0;
    width: 2px;
    box-shadow: 0 0 5px rgba(204, 102, 255, 0.7),
                0 0 10px rgba(204, 102, 255, 0.5);
  }
}

/* iOS-specific adjustments for loading screen */
@supports (-webkit-touch-callout: none) {
  .laser {
    height: 250vh;
  }
}

/* Shared Mission Styles */
.shared-mission-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.shared-mission-container {
  position: relative;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(147, 51, 234, 0.15);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.3s ease-out;
}

.shared-mission-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #9333ea;
  font-size: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(147, 51, 234, 0.2);
  z-index: 10;
  transition: all 0.2s ease;
}

.shared-mission-close:hover {
  background-color: #9333ea;
  color: white;
  transform: scale(1.05);
  box-shadow: 0 3px 8px rgba(147, 51, 234, 0.3);
}

.shared-mission-title {
  color: #9333ea;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(147, 51, 234, 0.2);
}

.shared-mission-container .mission-details {
  flex: 1;
  max-height: clamp(350px, 50vh, 500px);
  overflow-y: auto;
  border-radius: 16px;
  padding: 0;
  margin: 0 0 20px 0;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
  scrollbar-width: thin;
  scrollbar-color: rgba(153, 51, 255, 0.4) rgba(153, 51, 255, 0.1);
  position: relative;
}

.shared-mission-container .mission-details::-webkit-scrollbar {
  width: 6px;
}

.shared-mission-container .mission-details::-webkit-scrollbar-track {
  background: rgba(153, 51, 255, 0.05);
  border-radius: 4px;
}

.shared-mission-container .mission-details::-webkit-scrollbar-thumb {
  background-color: rgba(153, 51, 255, 0.3);
  border-radius: 4px;
}

.shared-mission-container .mission-details p {
  margin: 12px 0;
  line-height: 1.6;
  font-size: 16px;
  color: #333;
}

.shared-mission-container .location-info-card {
  margin-bottom: 12px;
  transition: transform 0.2s ease;
  position: relative;
}

/* Highlight items styling */
.shared-mission-container .highlight-item {
  margin: 8px 0;
  padding-left: 18px;
  position: relative;
  line-height: 1.5;
}

.shared-mission-container .highlight-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #9333ea;
  font-size: 18px;
  line-height: 1;
}

/* Mission notes section */
.shared-mission-container .mission-notes {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  margin: 12px 0;
  border-left: 3px solid rgba(147, 51, 234, 0.5);
  font-style: italic;
}

/* Maps link styling */
.shared-mission-container .maps-link {
  color: #9333ea;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: color 0.2s;
}

.shared-mission-container .maps-link:hover {
  color: #7c28ce;
}

.shared-mission-container .maps-link svg {
  margin-left: 4px;
  font-size: 16px;
}

/* Hours section styling */
.shared-mission-container .hours-section {
  margin: 15px 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.shared-mission-container .hours-title {
  font-weight: 600;
  margin-bottom: 4px;
  color: #555;
  font-size: 15px;
}

.shared-mission-container .hours-content {
  font-size: 14px;
  line-height: 1.4;
}

/* Section headings */
.shared-mission-container .section-heading {
  margin: 16px 0 10px;
  color: #9333ea;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  position: relative;
}

.shared-mission-container .section-heading::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background-color: rgba(147, 51, 234, 0.4);
  border-radius: 1px;
}

.shared-mission-actions {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.create-mission-button {
  background-color: #9333ea;
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 10px rgba(147, 51, 234, 0.3);
}

.create-mission-button:hover {
  background-color: #7c28ce;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(147, 51, 234, 0.4);
}

.create-mission-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(147, 51, 234, 0.3);
}

.loading-indicator {
  text-align: center;
  color: #9333ea;
  font-weight: 500;
  padding: 30px;
}

/* Scroll indicator */
.scroll-indicator {
  color: #9333ea;
  font-size: 14px;
  text-align: center;
  padding: 8px 0;
  opacity: 0.7;
  position: sticky;
  top: 0;
  background: white;
  z-index: 5;
  border-radius: 8px 8px 0 0;
  animation: pulseOpacity 1.5s infinite alternate;
}

@keyframes pulseOpacity {
  from { opacity: 0.7; }
  to { opacity: 0.3; }
}

/* iOS-specific adjustments for shared mission details */
@supports (-webkit-touch-callout: none) {
  .shared-mission-container .mission-details {
    max-height: clamp(300px, 45vh, 450px);
    padding-bottom: 0;
  }
}

/* For smaller screens, adjust shared mission container */
@media (max-height: 700px) {
  .shared-mission-container {
    padding: 20px 14px;
  }
  
  .shared-mission-container .mission-details {
    max-height: clamp(250px, 40vh, 400px);
  }
  
  .shared-mission-title {
    margin-bottom: 20px;
    font-size: 28px;
  }
}

/* For very small screens */
@media (max-height: 550px) {
  .shared-mission-container {
    padding: 16px 12px;
  }
  
  .shared-mission-container .mission-details {
    max-height: clamp(180px, 35vh, 300px);
  }
  
  .shared-mission-title {
    margin-bottom: 16px;
    font-size: 24px;
  }
}
