.login-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;
  animation: fadeIn 0.2s ease-out;
}

.login-popup {
  background-color: white;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
  padding: 2rem;
  box-shadow: 0 10px 25px rgba(119, 34, 221, 0.3);
  position: relative;
  animation: scaleIn 0.2s ease-out;
  transform-origin: center;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.login-popup-header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-popup-header h2 {
  font-size: 1.75rem;
  color: #333;
  margin: 0;
}

.login-popup-message {
  background-color: #f8f4ff;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 1.5rem;
  border-left: 4px solid #7722DD;
}

.login-popup-message p {
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #555;
}

.login-popup-message p:first-child {
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}

input {
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

input:focus {
  outline: none;
  border-color: #7722DD;
  box-shadow: 0 0 0 2px rgba(119, 34, 221, 0.2);
}

.login-button {
  background-color: #7722DD;
  color: white;
  font-weight: 600;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.login-button:hover {
  background-color: #6611CC;
}

.signup-link {
  color: #7722DD;
  text-decoration: underline;
  font-weight: 600;
  transition: color 0.2s;
  cursor: pointer;
}

.signup-link:hover {
  color: #6611CC;
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
} 