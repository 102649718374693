.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0.5rem 0;
  transition: transform 0.5s var(--transition-fn);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* Ensure navbar has consistent background */
  background-color: var(--primary-bg-color);
  /* Create subtle background blur */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.navbar::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Extend below the navbar */
  left: 0;
  width: 100%;
  height: 10px; /* Small gradient extension */
  background: linear-gradient(to bottom, var(--primary-bg-color), transparent);
  z-index: -1;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar.transitioning {
  will-change: transform;
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;

}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
  color: #000;
  transition: color var(--transition-duration) var(--transition-fn);
}

.logo-link:hover {
  color: var(--secondary-color);
}

/* Change logo to white in dark mode */
@media (prefers-color-scheme: dark) {
  .logo-link {
    color: #fff;
  }
}

/* For iOS dark mode */
.ios-device.ios-dark-mode .logo-link {
  color: #fff;
}

.logo-icon {
  width: 80px;
  height: 34px;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-link {
  color: var(--secondary-color);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  transition: color var(--transition-duration) var(--transition-fn);
  display: flex;
  align-items: center;
  gap: 0.1rem;
  letter-spacing: -0.2px;
}

.nav-link:hover {
  color: var(--primary-color);
}

.arrow-icon {
  width: 16px;
  height: 16px;
  transform: translateY(1px);
  transition: transform var(--transition-duration) var(--transition-fn);
}

.nav-link:hover .arrow-icon {
  transform: translate(0.5px, 0px);
}

.sign-in-button {
  background-color: #000;
  color: #fff;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  letter-spacing: -0.2px;
  padding: 0.35rem 0.8rem;
  border-radius: 999px;
  transition: background-color var(--transition-duration) var(--transition-fn);
}

.sign-in-button:hover {
  background-color: #333;
}

@media (max-width: 650px) {
  .navbar {
    padding: 0.4rem 0;
  }
  
  .nav-links {
    gap: 1rem;
  }
} 